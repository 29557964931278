/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { getUser } from '../services/auth'

const AppStateContext = React.createContext()
const { Provider, Consumer } = AppStateContext

function AppStateFlowProvider({ children }) {
  const [userId] = useState(getUser().id)

  return (
    <Provider
      value={{
        userId,
      }}
    >
      <>{children}</>
    </Provider>
  )
}

export { Consumer as AppStateFlowConsumer, AppStateContext }
export default AppStateFlowProvider
