export const ClevertapTracking = (eventName, props) => {
  if (window.com_my_oky) {
    if (props) {
      window.com_my_oky.pushEvent(eventName, JSON.stringify(props))
    } else {
      window.com_my_oky.pushEvent(eventName)
    }
  } else if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.com_my_oky
  ) {
    var message = {
      action: 'recordEventWithProps',
      event: eventName,
      properties: props || {},
    }
    window.webkit.messageHandlers.com_my_oky.postMessage(message)
  } else {
    const clevertap = require('clevertap-web-sdk')
    clevertap.event.push(eventName, props || {})
  }
}
