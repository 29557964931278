/* eslint-disable prettier/prettier */
import React from 'react'
import { render } from 'react-dom'
import ErrorModal from './ErrorModal'
import ErrorOky from '../icons/ErrorOky'
import ErrorConnectionOky from '../icons/ErrorConnectionOky'
import LocationOkOky from '../icons/LocationOkOky'
import SendOkOky from '../icons/SendOkOky'

export const ErrorModalGraphqlType = {
         VALE_DISABLED: {
           code: 'ERROR',
           header: '¡Oh No!',
           title: 'Vale no disponible!',
           description: 'El vale no esta disponible o esta desactivado',
           showHelp: 1,
           errorHandled: 1,
           icon: props => <ErrorOky {...props} />,
           button: {
             text: 'Ok',
           },
         },
         ERROR: {
           code: 'ERROR',
           header: '¡Error!',
           title: '¡Error!',
           showHelp: 1,
           errorHandled: 1,
           icon: props => <ErrorOky {...props} />,
           button: {
             text: 'Ok',
           },
         },
         CONNECTION: {
           id: 'ErrorModalGraphql',
           code: 'CONNECTION',
           header: '¡Oops!',
           title: 'Problemas de conexión',
           technical: 'Failed to fetch',
           showHelp: 1,
           description:
             'Vemos que tu tienes problemas de conexión en tu móvil, intenta de nuevo',
           icon: props => <ErrorConnectionOky {...props} />,
           button: {
             text: 'Volver a cargar',
             onClick: () => window.location.reload(),
           },
         },
         LOCATION: {
           icon: props => <LocationOkOky {...props} />,
         },
         SEND: {
           icon: props => <SendOkOky {...props} />,
         },
       }

export const ErrorModalGraphql = props => {
  return <ErrorModal {...props} />
}

const dispatch = (content, options) => {
  const id = 'ErrorModalGraphql'
  const element = document.getElementById(`dialog-${id}`)
  if (element) element.remove()
  const containerDomNode = document.createElement('div')
  containerDomNode.setAttribute('id', id)
  document.body.appendChild(containerDomNode)
  render(
    <ErrorModalGraphql
      {...options}
      id={id}
      show
      content={content}
      header={options?.header}
      title={options?.title}
      icon={options?.icon}
      code={options?.code}
      type={options?.type}
      mixpanel={options?.mixpanelTr}
      showHelp={options?.showHelp}
      errorHandled={options?.errorHandled}
    />,
    containerDomNode,
  )
}

export const useErrorModalGraphql = (content, options) =>
  dispatch(content, options)
