import * as Sentry from '@sentry/gatsby'

Sentry.init({
  enabled: process.env.SENTRY_ENABLED === 'TRUE',
  dsn: process.env.SENTRY_DSN_KEY,
  environment: process.env.SENTRY_ENVIRONMENT,
  sampleRate: parseFloat(process.env.SENTRY_SAMPLE_RATE),
  tracesSampleRate: parseFloat(process.env.SENTRY_SAMPLE_TRACE),
  replaysSessionSampleRate: parseFloat(process.env.SENTRY_REPLAYSSESSION),
  replaysOnErrorSampleRate: parseFloat(process.env.SENTRY_REPLAYSON),
  debug: process.env.SENTRY_DEBUG === 'TRUE',
  integrations: [
    Sentry.browserTracingIntegration({
      beforeStartSpan: context => {
        const pathname = location.pathname
        const parts = pathname.split('/').filter(part => part)
        const truncatedPathname =
          parts.length > 1 ? `/${parts[0]}/${parts[1]}` : pathname

        return {
          ...context,
          name: truncatedPathname,
        }
      },
    }),
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        'https://graphql.okyapp.com/',
        'https://qa-graphql-backend.okyapp.com/',
      ],
      networkRequestHeaders: ['X-Custom-Header'],
      networkResponseHeaders: ['X-Custom-Header'],
    }),
  ],
  tracePropagationTargets: [
    'https://graphql.okyapp.com',
    'https://qa-graphql-backend.okyapp.com',
    'https://dev.okyapp.com',
    'https://mi.okyapp.com',
    /^\//,
  ],
  beforeSend(event, hint) {
    if (hint && hint.originalException && hint.originalException._sentry_) {
      const span = hint.originalException._sentry_
      event.contexts('baggage', span.getTraceContext())
    }
    return event
  },
})

Sentry.withScope(scope => {
  scope.addEventProcessor(event => {
    const span = scope.getSpan()
    if (span) {
      event.request.headers = {
        ...event.request.headers,
        'sentry-trace': span.toTraceparent(),
      }
    }
    return event
  })
})
