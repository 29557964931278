
export const OKY_SURFACE_0 = '#FFFFFF'
export const OKY_SURFACE_1 = '#f5f5f5'
export const OKY_SURFACE_2 = '#eeeeee'
export const OKY_SURFACE_3 = '#e0e0e0'
export const OKY_SURFACE_4 = '#bdbdbd'
export const OKY_SURFACE_5 = '#9e9e9e'
export const OKY_SURFACE_6 = '#757575'
export const OKY_SURFACE_7 = '#616161'
export const OKY_SURFACE_8 = '#424242'
export const OKY_SURFACE_9 = '#212121'
export const OKY_ACQUA_ONLIGHT_0 = '#ebfffe'
export const OKY_ACQUA_ONLIGHT_05 = '#d4fefb'
export const OKY_ACQUA_ONLIGHT_1 = '#bdfdf9'
export const OKY_ACQUA_ONLIGHT_15 = '#a6faf4'
export const OKY_ACQUA_ONLIGHT_2 = '#8ff7f0'
export const OKY_ACQUA_ONLIGHT_3 = '#66ece3'
export const OKY_ACQUA_ONLIGHT_4 = '#44ddd2'
export const OKY_ACQUA_ONLIGHT_5 = '#2ac9be'
export const OKY_ACQUA_ONLIGHT_6 = '#18b1a7'
export const OKY_ACQUA_ONLIGHT_7 = '#0c968d'
export const OKY_ACQUA_ONLIGHT_8 = '#057971'
export const OKY_ACQUA_ONLIGHT_9 = '#015d57'
export const OKY_ACQUA_ONLIGHT_10 = '#004540'
export const OKY_ACQUA_ONDARK_05 = '#024f4a'
export const OKY_ACQUA_ONDARK_1 = '#045953'
export const OKY_ACQUA_ONDARK_15 = '#07645e'
export const OKY_ACQUA_ONDARK_2 = '#0a6f68'
export const OKY_ACQUA_ONDARK_3 = '#128981'
export const OKY_ACQUA_ONDARK_4 = '#21b4aa'
export const OKY_ACQUA_ONDARK_5 = '#2fc9be'
export const OKY_ACQUA_ONDARK_6 = '#40d5cb'
export const OKY_ACQUA_ONDARK_7 = '#64e0d8'
export const OKY_ACQUA_ONDARK_8 = '#95ebe5'
export const OKY_ACQUA_ONDARK_9 = '#c1f5f2'
export const OKY_PURPLE_ONLIGHT_0 = '#faf5ff'
export const OKY_PURPLE_ONLIGHT_05 = '#e4d5f3'
export const OKY_PURPLE_ONLIGHT_1 = '#cfb7e7'
export const OKY_PURPLE_ONLIGHT_15 = '#bb9cdb'
export const OKY_PURPLE_ONLIGHT_2 = '#a883d0'
export const OKY_PURPLE_ONLIGHT_3 = '#875ab8'
export const OKY_PURPLE_ONLIGHT_4 = '#6c3ba0'
export const OKY_PURPLE_ONLIGHT_5 = '#552588'
export const OKY_PURPLE_ONLIGHT_6 = '#421771'
export const OKY_PURPLE_ONLIGHT_7 = '#320d59'
export const OKY_PURPLE_ONLIGHT_8 = '#230741'
export const OKY_PURPLE_ONLIGHT_9 = '#16042a'
export const OKY_PURPLE_ONLIGHT_10 = '#090112'
export const OKY_PURPLE_ONDARK_05 = '#10031e'
export const OKY_PURPLE_ONDARK_1 = '#17052a'
export const OKY_PURPLE_ONDARK_15 = '#1e0735'
export const OKY_PURPLE_ONDARK_2 = '#250a41'
export const OKY_PURPLE_ONDARK_3 = '#341259'
export const OKY_PURPLE_ONDARK_4 = '#451c71'
export const OKY_PURPLE_ONDARK_5 = '#572888'
export const OKY_PURPLE_ONDARK_6 = '#6b3aa0'
export const OKY_PURPLE_ONDARK_7 = '#895cb8'
export const OKY_PURPLE_ONDARK_8 = '#ae8ed0'
export const OKY_PURPLE_ONDARK_9 = '#d3c0e7'
export const OKY_YELLOW_ONLIGHT_0 = '#fff8e9'
export const OKY_YELLOW_ONLIGHT_05 = '#fef2d4'
export const OKY_YELLOW_ONLIGHT_1 = '#feebbf'
export const OKY_YELLOW_ONLIGHT_15 = '#fde4aa'
export const OKY_YELLOW_ONLIGHT_2 = '#fdde96'
export const OKY_YELLOW_ONLIGHT_3 = '#fbd06c'
export const OKY_YELLOW_ONLIGHT_4 = '#f9c243'
export const OKY_YELLOW_ONLIGHT_5 = '#f6b41a'
export const OKY_YELLOW_ONLIGHT_6 = '#f0a800'
export const OKY_YELLOW_ONLIGHT_7 = '#e7a200'
export const OKY_YELLOW_ONLIGHT_8 = '#d59500'
export const OKY_YELLOW_ONLIGHT_9 = '#ac7900'
export const OKY_YELLOW_ONLIGHT_10 = '#573d00'
export const OKY_YELLOW_ONDARK_05 = '#8d6300'
export const OKY_YELLOW_ONDARK_1 = '#c38800'
export const OKY_YELLOW_ONDARK_15 = '#cc8f00'
export const OKY_YELLOW_ONDARK_2 = '#d69600'
export const OKY_YELLOW_ONDARK_3 = '#df9c00'
export const OKY_YELLOW_ONDARK_4 = '#e5a000'
export const OKY_YELLOW_ONDARK_5 = '#eaab19'
export const OKY_YELLOW_ONDARK_6 = '#eeba40'
export const OKY_YELLOW_ONDARK_7 = '#f2c968'
export const OKY_YELLOW_ONDARK_8 = '#f7d892'
export const OKY_YELLOW_ONDARK_9 = '#fbe8bd'
export const DARK_1 = '#000000'
export const PRIMARY = '#000000de'
export const SECONDARY = '#0000008a'
export const DISABLED_HINT = '#00000061'
export const HOVER = '#0000000a'
export const SELECTED = '#00000014'
export const DISABLED_BG_FOCUS = '#0000001f'
export const DISABLED = '#00000042'
export const BORDER = '#0000003b'
export const BACKDROP = '#00000080'
export const SNACKBAR = '#323232'
export const ACTIVE_RATING = '#ffb400'
export const ACTIVE_LOVING = '#FF6D75'
export const KNOB_INACTIVEKNOB = '#fafafa'
export const TEXT_DARK = '#0D3C61'
export const MAIN = '#2196f3'
export const DARK = '#0b79d0'
export const LIGHT = '#64b6f7'
export const LIGHT_BG = '#E9F5FE'
export const SUCCESS_TEXT_DARK = '#1E4620'
export const SUCCESS_MAIN = '#4caf50'
export const SUCCESS_DARK = '#3b873e'
export const SUCCESS_LIGHT = '#7bc67e'
export const SUCCESS_LIGHT_BG = '#EDF7ED'
export const ERROR_TEXT_DARK = '#621B16'
export const ERROR_MAIN = '#f44336'
export const ERROR_DARK = '#e31b0c'
export const ERROR_LIGHT = '#f88078'
export const ERROR_LIGHT_BG = '#FEECEB'
export const WARNING_TEXT_DARK = '#663D00'
export const WARNING_MAIN = '#ff9800'
export const WARNING_DARK = '#c77700'
export const WARNING_LIGHT = '#ffb547'
export const WARNING_LIGHT_BG = '#FFF5E5'
export const GREY_A100 = '#d5d5d5'
export const GREY_A200 = '#aaaaaa'
export const GREY_A700 = '#303030'
export const GREEN_ = '#0BD0C3'

export default {
  OKY_SURFACE_0,
  OKY_SURFACE_1,
  OKY_SURFACE_2,
  OKY_SURFACE_3,
  OKY_SURFACE_4,
  OKY_SURFACE_5,
  OKY_SURFACE_6,
  OKY_SURFACE_7,
  OKY_SURFACE_8,
  OKY_SURFACE_9,
  OKY_ACQUA_ONLIGHT_0,
  OKY_ACQUA_ONLIGHT_05,
  OKY_ACQUA_ONLIGHT_1,
  OKY_ACQUA_ONLIGHT_15,
  OKY_ACQUA_ONLIGHT_2,
  OKY_ACQUA_ONLIGHT_3,
  OKY_ACQUA_ONLIGHT_4,
  OKY_ACQUA_ONLIGHT_5,
  OKY_ACQUA_ONLIGHT_6,
  OKY_ACQUA_ONLIGHT_7,
  OKY_ACQUA_ONLIGHT_8,
  OKY_ACQUA_ONLIGHT_9,
  OKY_ACQUA_ONLIGHT_10,
  OKY_ACQUA_ONDARK_05,
  OKY_ACQUA_ONDARK_1,
  OKY_ACQUA_ONDARK_15,
  OKY_ACQUA_ONDARK_2,
  OKY_ACQUA_ONDARK_3,
  OKY_ACQUA_ONDARK_4,
  OKY_ACQUA_ONDARK_5,
  OKY_ACQUA_ONDARK_6,
  OKY_ACQUA_ONDARK_7,
  OKY_ACQUA_ONDARK_8,
  OKY_ACQUA_ONDARK_9,
  OKY_PURPLE_ONLIGHT_0,
  OKY_PURPLE_ONLIGHT_05,
  OKY_PURPLE_ONLIGHT_1,
  OKY_PURPLE_ONLIGHT_15,
  OKY_PURPLE_ONLIGHT_2,
  OKY_PURPLE_ONLIGHT_3,
  OKY_PURPLE_ONLIGHT_4,
  OKY_PURPLE_ONLIGHT_5,
  OKY_PURPLE_ONLIGHT_6,
  OKY_PURPLE_ONLIGHT_7,
  OKY_PURPLE_ONLIGHT_8,
  OKY_PURPLE_ONLIGHT_9,
  OKY_PURPLE_ONLIGHT_10,
  OKY_PURPLE_ONDARK_05,
  OKY_PURPLE_ONDARK_1,
  OKY_PURPLE_ONDARK_15,
  OKY_PURPLE_ONDARK_2,
  OKY_PURPLE_ONDARK_3,
  OKY_PURPLE_ONDARK_4,
  OKY_PURPLE_ONDARK_5,
  OKY_PURPLE_ONDARK_6,
  OKY_PURPLE_ONDARK_7,
  OKY_PURPLE_ONDARK_8,
  OKY_PURPLE_ONDARK_9,
  OKY_YELLOW_ONLIGHT_0,
  OKY_YELLOW_ONLIGHT_05,
  OKY_YELLOW_ONLIGHT_1,
  OKY_YELLOW_ONLIGHT_15,
  OKY_YELLOW_ONLIGHT_2,
  OKY_YELLOW_ONLIGHT_3,
  OKY_YELLOW_ONLIGHT_4,
  OKY_YELLOW_ONLIGHT_5,
  OKY_YELLOW_ONLIGHT_6,
  OKY_YELLOW_ONLIGHT_7,
  OKY_YELLOW_ONLIGHT_8,
  OKY_YELLOW_ONLIGHT_9,
  OKY_YELLOW_ONLIGHT_10,
  OKY_YELLOW_ONDARK_05,
  OKY_YELLOW_ONDARK_1,
  OKY_YELLOW_ONDARK_15,
  OKY_YELLOW_ONDARK_2,
  OKY_YELLOW_ONDARK_3,
  OKY_YELLOW_ONDARK_4,
  OKY_YELLOW_ONDARK_5,
  OKY_YELLOW_ONDARK_6,
  OKY_YELLOW_ONDARK_7,
  OKY_YELLOW_ONDARK_8,
  OKY_YELLOW_ONDARK_9,
  DARK_1,
  PRIMARY,
  SECONDARY,
  DISABLED_HINT,
  HOVER,
  SELECTED,
  DISABLED_BG_FOCUS,
  DISABLED,
  BORDER,
  BACKDROP,
  SNACKBAR,
  ACTIVE_RATING,
  ACTIVE_LOVING,
  KNOB_INACTIVEKNOB,
  TEXT_DARK,
  MAIN,
  DARK,
  LIGHT,
  LIGHT_BG,
  TEXT_DARK,
  SUCCESS_MAIN,
  SUCCESS_DARK,
  SUCCESS_LIGHT,
  SUCCESS_LIGHT_BG,
  ERROR_TEXT_DARK,
  ERROR_MAIN,
  ERROR_DARK,
  ERROR_LIGHT,
  ERROR_LIGHT_BG,
  WARNING_TEXT_DARK,
  WARNING_MAIN,
  WARNING_DARK,
  WARNING_LIGHT,
  WARNING_LIGHT_BG,
  GREY_A100,
  GREY_A200,
  GREY_A700,
  GREEN_,
}
