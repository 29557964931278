import React, { useEffect, useState } from 'react'
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CardMedia from '@material-ui/core/CardMedia'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import theme from '../../../plugins/gatsby-plugin-top-layout/theme/index'
import ModalHelp from '../common/modalHelp'
import { Grid } from '@material-ui/core'
import mixpanel from 'mixpanel-browser'

function ErrorModal({
  id,
  content,
  show,
  header,
  title,
  icon,
  code,
  type,
  showHelp,
  errorHandled,
}) {
  const useStyles = makeStyles(theme => ({
    dialog: {
      '& .MuiDialog-paper': {
        height: '535px',
        borderRadius: 30,
        position: 'fixed',
        bottom: 0,
        margin: 0,
      },
      [theme.breakpoints.down('sm')]: {
        '& .MuiDialog-paper': {
          borderRadius: 30,
          width: '100%',
          position: 'fixed',
          bottom: 0,
          margin: 0,
        },
      },
    },
    header: {
      with: '192px',
      height: '24px',
      fontSize: '22px !important',
      fontWeight: 'bold',
      paddingTop: '5px',
      color: '#552588',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: '2px',
      color: '#552588',
    },
    content: {
      height: '323px',
      borderLeft: 'none',
      borderRight: 'none',
    },
    icon: {
      '& svg': {
        display: 'block',
        margin: '0 auto',
      },
    },
    title: {
      color: '#000000',
      fontWeight: 'bold',
      textAlign: 'center',
      fontSize: 20,
      width: '100%',
    },
    message: {
      height: '46px',
      width: '100%',
      color: '#552588',
      textAlign: 'center',
      fontSize: '18px',
      fontWeight: 'bold',
    },
    titleCode: {
      color: 'rgba(0, 0, 0, 0.541176)',
      fontWeight: 'bold',
      marginLeft: 10,
      fontSize: 18,
      display: 'none',
    },
    okButton: {
      color: '#552588',
      fontWeight: 'bold',
      textAlign: 'center',
      margin: '0 auto',
      textTransform: 'none',
    },
    okButtonTitle: {
      color: '#552588',
      fontWeight: 'bold',
      marginLeft: 10,
    },
    imageIcon: {
      backgroundColor: 'white',
      width: '50%',
      margin: '0 auto',
    },
    MuiIconPhoneAwesome: {
      marginRight: 10,
    },
    MuiButtomHelp: {
      textAlign: 'center',
      padding: '16px 36px',
      borderRadius: '32px',
      fontFamily: "'Lato'",
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '160%',
      color: '#FFFFFF',
      textTransform: 'none',
      backgroundColor: '#4CAF50',
      marginBottom: 30,
      width: '90%',
      height: 47,
      '&:hover': {
        background: '#E31B0C',
      },
    },
  }))
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  var mixpanelTr = require('mixpanel-browser')
  mixpanelTr.init(mixpanel)
  const [canTrack] = useState(!!mixpanel)
  const [openModalHelp, setOpenModalHelp] = React.useState(false)
  const clevertap = require('clevertap-web-sdk')

  const handlerHelpModal = () => {
    setOpenModalHelp(true)
    setOpen(false)
    if (typeof window !== `undefined`) {
      clevertap.event.push('Error Message Help Button')
      if (mixpanel) {
        mixpanel.track('Error Message Help Button')
      }
    }
  }
  const handleClose = () => {
    setOpen(false)
  }

  const DialogTitle = props => {
    const { children, onClose, ...other } = props
    return (
      <MuiDialogTitle
        style={{ height: '72px', display: 'flex' }}
        disableTypography
        {...other}
      >
        <Typography className={classes.header}>{children}</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon style={{ width: '30px', fontSize: 'xxx-large' }} />
        </IconButton>
      </MuiDialogTitle>
    )
  }

  useEffect(() => {
    setOpen(show)
    if (show) {
      if (errorHandled) {
        if (typeof window !== `undefined`) {
          if (canTrack && mixpanelTr?.track) {
            mixpanel.track('Message Displayed', {
              'Message Type': 'Business Message',
              'Error Id': code || '',
              'Error Code': type || '',
              'Error title': title || '',
              'Error description': content || '',
            })
          }
          if (clevertap?.event) {
            clevertap.event.push('Message Displayed', {
              'Message Type': 'Business Message',
              'Error Id': code || '',
              'Error Code': type || '',
              'Error title': title || '',
              'Error description': content || '',
            })
          }
        }
      } else {
        if (typeof window !== `undefined`) {
          if (canTrack && mixpanelTr?.track) {
            mixpanel.track('Message Displayed', {
              'Message Type': 'Technical Issue',
              'Error Id': code || '',
              'Error Code': type || '',
              'Error title': title || '',
              'Error description': content || '',
            })
          }
          if (clevertap?.event) {
            clevertap.event.push('Message Displayed', {
              'Message Type': 'Technical Issue',
              'Error Id': code || '',
              'Error Code': type || '',
              'Error title': title || '',
              'Error description': content || '',
            })
          }
        }
      }
    }
  }, [show, canTrack])

  return (
    <MuiThemeProvider theme={theme}>
      <Grid item xs={12}>
        {openModalHelp ? (
          <ModalHelp
            mixpanel={mixpanel}
            openModalHelp={openModalHelp}
            setOpenModalHelp={setOpenModalHelp}
          />
        ) : null}
      </Grid>
      <Dialog
        id={`dialog-${id}`}
        maxWidth="lg"
        className={classes.dialog}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle disableTypography>
          <div
            dangerouslySetInnerHTML={{ __html: header || '¡Error!' }}
            style={{ display: 'inline-block' }}
          />
          {code && <span className={classes.titleCode}>{code}</span>}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText className={classes.icon}>
            <CardMedia
              component="img"
              image={icon || '/image/error-general.webp'}
              className={classes.imageIcon}
            />
          </DialogContentText>
          <DialogContentText className={classes.message}>
            <div
              dangerouslySetInnerHTML={{ __html: content || '¡Error!' }}
              style={{ display: 'inline-block' }}
            />
          </DialogContentText>
        </DialogContent>
        {showHelp === 1 && (
          <DialogActions>
            <Grid
              style={{ textAlign: 'center' }}
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Button
                variant="contained"
                className={classes.MuiButtomHelp}
                onClick={handlerHelpModal}
              >
                <FontAwesomeIcon
                  className={classes.MuiIconPhoneAwesome}
                  icon={['fas', 'fa-comments']}
                />{' '}
                Ayuda
              </Button>
            </Grid>
          </DialogActions>
        )}
      </Dialog>
    </MuiThemeProvider>
  )
}

export default ErrorModal
