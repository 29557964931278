import React from 'react'
import { ApolloProvider } from './providers'
import { MixpanelProvider } from 'react-mixpanel-browser'
const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider>
      <MixpanelProvider>{element}</MixpanelProvider>
    </ApolloProvider>
  )
}

export default wrapRootElement
