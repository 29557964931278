import React from 'react'
import theme from './theme/index'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider } from '@material-ui/core/styles'
import AppStateFlowProvider from '../../src/providers/stateFlow'

const wrapRootElement = ({ element }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <AppStateFlowProvider>{element}</AppStateFlowProvider>
    </MuiThemeProvider>
  )
}

export default wrapRootElement
